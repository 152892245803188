import React from 'react';
import { useNetworkStatus } from './useNetworkStatus';
import { toast } from 'react-toastify';
// import { useNetworkSpeed } from './useNetworkSpeed';


const NetworkStatusIndicator = () => {
  const isOnline = useNetworkStatus();
  // const networkSpeed = useNetworkSpeed();

  return (
    <div>
      {isOnline ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          width: '100px'
        }}>
          <div style={{ width: '12px', height: '12px', background: 'green', borderRadius: '50%' }}></div> &nbsp; Online </div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
          width: '300px'
        }}>
          <div style={{ width: '12px', height: '12px', background: 'red', borderRadius: '50%' }}></div> &nbsp; Offline - Check your connection
        </div>
      )}
      {/* {networkSpeed}&nbsp;Mbps */}
    </div>
  );
};

export default NetworkStatusIndicator;