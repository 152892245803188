import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from '../popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';

import ZohoLogo from '../../../assets/images/zoho-logo-web.svg';
import checkMark from '../../../assets/images/checkmark-circle.svg';
import PreLoader from '../preLoader/PreLoader';
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

export default function ZohoIntegration() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [connectedToZoho, setConnectedToZoho] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disconnectPopup, setDisconnectPopup] = useState(false);

  const titleCss = {
    fontSize: '16px',
    fontWeight: '600',
    color: '#726b6b',
    marginTop: '10px',
    marginBottom: '20px',
  };
  const [formData, setFormData] = useState({
    client_id: '',
    client_secret: '',
    error: '',
  });
  const [codeValue, setCodeValue] = useState(null);

  const closePopup = () => setIsPopupOpen(false);

  const inputHandler = (e) => {
    const { id, value } = e.target;
    console.log('id', id, value);
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleZohoDisconnect = async () => {
    try {
      await DELETE(`${orgUrl}v1/organisation/zoho-integration`, null);
      setConnectedToZoho(false);
    } catch (err) {
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  const handleZohoRedirect = () => {
    if (formData.client_id === '' || formData.client_secret === '')
      return toast.error('provide details');
    localStorage.setItem('zoho_client_id', formData.client_id);
    localStorage.setItem('zoho_secret', formData.client_secret);
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?response_type=code&client_id=${formData.client_id}&scope=ZohoCRM.modules.ALL&redirect_uri=https://business.dev.alohaa.ai/hybrid-integrations&access_type=offline`;
  };

  const getZohoConnect = async () => {
    try {
      const data = await GET(`${orgUrl}v1/organisation/zoho-integration`, null);
      setConnectedToZoho(
        data?.data?.response?.zohoIntegrationData?.client_secret ? true : false
      );
    } catch (err) {
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  const saveZohoData = async (codeReceived) => {
    try {
      setProcessing(true);
      const data = await PUT(
        `${orgUrl}v1/organisation/zoho-integration`,
        null,
        {
          client_id: localStorage.getItem('zoho_client_id'),
          client_secret: localStorage.getItem('zoho_secret'),
          code: codeReceived,
        }
      );
      setConnectedToZoho(
        data?.data?.response?.zohoIntegrationData?.client_secret ? true : false
      );
      setProcessing(false);
    } catch (err) {
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
      setProcessing(false);
    }
  };

  useEffect(() => {
    // Extract the 'code' parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code'); // Get the value of 'code'

    if (code) {
      setCodeValue(codeValue); // Set the code state with the value
      saveZohoData(code);
      localStorage.removeItem('zoho_client_id');
      localStorage.removeItem('zoho_secret');
    }

    getZohoConnect();
  }, []); // Runs only once when the component mounts

  return (
    <>
      {processing && <PreLoader />}
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer" style={{ overflow: 'auto' }}>
          <div className="PopupHeaderContainer">
            <p>Add Zoho CRM Details</p>
            <img
              alt="Close"
              className="PopupClose"
              src={Close}
              onClick={closePopup}
            />
          </div>
          <div className="PopupBody">
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Client Id</label>
              <input
                className="PopupInput"
                id="client_id"
                value={formData.client_id}
                onChange={inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Client Secret</label>
              <input
                className="PopupInput"
                id="client_secret"
                value={formData.client_secret}
                onChange={inputHandler}
              />
            </div>

            {formData.error && (
              <div className="ErrorContainer ErrorContainerMargin">
                <img
                  alt="Error"
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {formData.error}
              </div>
            )}

            <p className="PopupNote">
              Note: You will be redirected to zoho portal.
            </p>
            <button
              className="ButtonFullWidth BgGreen"
              onClick={handleZohoRedirect}
              style={{ marginTop: '20px' }}
            >
              SAVE
            </button>
          </div>
        </div>
      </Popup>

      <Popup
        open={disconnectPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Disconnect Zoho Integration</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => setDisconnectPopup(false)}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupBodyTitle">
              Are you sure you want to disconnect
              <br /> ZOHO integration
            </p>

            <button
              className="ButtonFullWidth BgRed"
              onClick={() => {
                handleZohoDisconnect();
                setDisconnectPopup(false);
              }}
            >
              REMOVE
            </button>
          </div>
        </div>
      </Popup>
      <div style={styles.card}>
        <div style={styles.imageContainer}>
          <img src={ZohoLogo} alt="ZOHO CRM" style={styles.image} />
        </div>
        <h3 style={!connectedToZoho ? titleCss : styles.title}>ZOHO CRM</h3>
        <div
          style={{
            marginBottom: '10px',
            display: !connectedToZoho && 'none',
          }}
        >
          <img src={checkMark} alt="checkMark" style={styles.checkMark} />
          <span style={styles.connectedText}>Connected</span>
        </div>
        {!connectedToZoho ? (
          <button onClick={() => setIsPopupOpen(true)} style={styles.button}>
            Configure Webhook
          </button>
        ) : (
          <button
            style={styles.buttonRed}
            onClick={() => setDisconnectPopup(true)}
          >
            Remove Webhook
          </button>
        )}
      </div>
    </>
  );
}

const styles = {
  card: {
    height: '256px',
    width: '256px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '12px',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  imageContainer: {
    display: 'grid',
    height: '128px',
    border: 'solid 2px lightgrey',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  image: {
    width: '104px',
    height: '45px',
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#726b6b',
    marginTop: '5px',
    marginBottom: '0px',
  },
  checkMark: {
    height: '11px',
    width: '11px',
  },
  connectedText: {
    marginLeft: '5px',
    fontSize: '11px',
    marginBottom: '30px',
  },
  button: {
    display: 'grid',
    height: '40px',
    width: '212px',
    margin: 'auto',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#28a745',
    border: 'none',
    borderRadius: '26px',
    cursor: 'pointer',
    alignContent: 'space-evenly',
  },
  buttonRed: {
    height: '40px',
    width: '212px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#F8153B',
    backgroundColor: '#fff',
    border: '1.5px solid #F8153B',
    borderRadius: '26px',
    cursor: 'pointer',
  },
};
